'use client';
import styled from '@emotion/styled';
import { darken } from 'color2k';
import Link from 'next/link';
import { ifProp } from 'styled-tools';

export const StyledLink = styled(Link)<{ color?: string; noHover?: boolean }>(
    ({ theme, color }) => ({
        textDecoration: 'none',
        color: color || theme.colors.primary,
        ':hover': {
            textDecoration: 'underline',
            color: darken(color ?? theme.colors.primary, 0.1),
        },
    }),
    ifProp('noHover', () => ({
        ':hover': {
            textDecoration: 'none',
            color: 'inherit',
        },
    }))
);

export const StyledButtonLink = styled.button<{ color?: string; noHover?: boolean }>(
    ({ theme, color }) => ({
        textDecoration: 'none',
        color: color || theme.colors.primary,
        ':hover': {
            textDecoration: 'underline',
            color: darken(color ?? theme.colors.primary, 0.1),
        },
    }),
    ifProp('noHover', () => ({
        ':hover': {
            textDecoration: 'none',
            color: 'inherit',
        },
    }))
);
