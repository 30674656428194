import styled from '@emotion/styled';

export const StyledRoot = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.colors.headerSearchInputBorderColor}`,
    borderRadius: theme.space[1],
    padding: `0 ${theme.space[2]}`,
}));
