import styled from '@emotion/styled';
import { mq } from '~/lib';
import Flex from '~/shared/components/Flex';

export const StyledStar = styled.span(({ theme }) => ({
    width: theme.fontSizes['2xs'],
    marginRight: 2,
    height: '100%',
    display: 'inline-flex',
    '& > svg': {
        width: '100%',
    },
    [mq('sm')]: {
        width: theme.fontSizes['sm'],
    },
}));

export const StyledTotalRatings = styled.span(({ theme }) => ({
    ...theme.mixins.useTextStyle('caption'),
    color: theme.colors.grey100,
    marginLeft: theme.space['1'],
}));

export const StyledWrapper = styled(Flex)<{ onClick: () => void }>(({ theme }) => ({
    color: theme.colors.grey70,
    cursor: 'pointer',

    [mq('sm')]: {
        paddingTop: theme.space['2'],
    },
}));
