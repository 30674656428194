import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RefObject, useEffect, useRef } from 'react';

export type SlickCarouselProps = {
    leftArrow?: RefObject<HTMLButtonElement>;
    rightArrow?: RefObject<HTMLButtonElement>;
    settings: SlickSettings;
    children: React.ReactNode;
    activeSlide?: number;
};

export type SlickSettings = Settings;

export function SlickCarousel(props: SlickCarouselProps) {
    const { children, settings, leftArrow, rightArrow, activeSlide } = props;
    const sliderRef = useRef<Slider>(null);

    const goBack = () => {
        sliderRef.current?.slickPrev();
    };

    const goForward = () => {
        sliderRef.current?.slickNext();
    };

    useEffect(() => {
        if (activeSlide) {
            sliderRef.current?.slickGoTo(activeSlide);
        }
    }, [activeSlide]);

    useEffect(() => {
        if (!leftArrow?.current) return;
        leftArrow.current.addEventListener('click', goBack, false);

        return () => {
            if (!leftArrow?.current) return;
            leftArrow.current.removeEventListener('click', goBack, false);
        };
    }, [leftArrow?.current, sliderRef.current]);

    useEffect(() => {
        if (!rightArrow?.current) return;
        rightArrow.current.addEventListener('click', goForward, false);
        return () => {
            if (!rightArrow?.current) return;
            rightArrow.current.removeEventListener('click', goForward, false);
        };
    }, [rightArrow?.current, sliderRef.current]);

    const baseSettings: Settings = {
        infinite: false,
        slidesToShow: 6,
        slidesPerRow: 1,
        rows: 1,
        accessibility: true,
        adaptiveHeight: true,
        swipeToSlide: true,
        easing: 'ease-in-out',
        arrows: false,
    };

    return (
        <Slider ref={sliderRef} {...{ ...baseSettings, ...settings }}>
            {children}
        </Slider>
    );
}
