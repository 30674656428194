import { Text } from '$shared/components';
import { useId } from '@radix-ui/react-id';
import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { AccordionContext } from '../../context/AccordionContext';
import {
    StyledAccordionContainer,
    StyledAccordionContent,
    StyledAccordionIconWrapper,
    StyledAccordionItem,
    StyledAccordionTrigger,
    StyledDefaultIcon,
} from './styled';
import Minus from '$icons/minus.svg';
import Plus from '$icons/plus.svg';

export type AccordionItemChildrenRenderProps = {
    isOpen: boolean;
    toggle: () => void;
    open: () => void;
    close: () => void;
    id: string;
};

export type AccordionItemProps = {
    /**
     * Text for the headline OR a component that will replace the default header and be used to trigger for the accordion
     */
    header?: string | ReactNode;

    /**
     * Controlls if the accordion is open or not
     */
    initAsOpen?: boolean;

    /**
     * Override the unique generated id
     */
    id?: string;

    /**
     * Overwrite the default icon
     */
    icon?: ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);

    /**
     * Accordion content
     */
    children?: React.ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);
};

const defaultIcon = ({ isOpen }: { isOpen: boolean }) => (
    <StyledDefaultIcon as={isOpen ? Minus : Plus} />
);

/**
 * @description
 * Wraps content (children) in an accordion.
 * Should always be used inside a <Accordion>
 *
 * @example
 *  <AccordionItem
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion" />
 *      <p>Accordion body here!</p>
 *  </AccordionItem>
 *
 * @example
 *  <AccordionItem
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion" />
 * .    {({ isOpen }) => (
 *         `This accordion is ${isOpen ? 'open' : 'closed' }!`
 *      )}
 *  </AccordionItem>
 */

export const AccordionItem: FC<AccordionItemProps> = ({
    id: initialId,
    header,
    icon = defaultIcon,
    initAsOpen = false,
    children,
}) => {
    // Ensures present id. Fallback unique id
    const id = useId(initialId);

    const { states, disableAnimation, ...context } = useContext(AccordionContext);
    const isOpen = useMemo(() => states.includes(id), [states]);
    const methods = useMemo(() => {
        return {
            toggle: () => context.toggle(id),
            open: () => context.open(id),
            close: () => context.close(id),
        };
    }, [id, context]);

    useEffect(() => {
        initAsOpen && methods.close();
    }, []);

    return (
        <StyledAccordionItem value={id}>
            <StyledAccordionTrigger>
                {typeof header === 'string' ? (
                    <Text variant="display4" children={header} />
                ) : (
                    header
                )}
                {icon && (
                    <StyledAccordionIconWrapper>
                        {typeof icon === 'function' ? icon({ id, isOpen, ...methods }) : icon}
                    </StyledAccordionIconWrapper>
                )}
            </StyledAccordionTrigger>
            <StyledAccordionContainer disableAnimation={disableAnimation} forceMount={true}>
                <StyledAccordionContent>
                    <>
                        {typeof children === 'function'
                            ? children({ id, isOpen, ...methods })
                            : children}
                    </>
                </StyledAccordionContent>
            </StyledAccordionContainer>
        </StyledAccordionItem>
    );
};
