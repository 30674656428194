import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { ShouldNotForwardProps } from '~/shared/utils/styled';

type StyledImageWrapperProps = {
    loading: boolean;
    disableSkeleton: boolean;
};

export const StyledImageWrapper = styled('span', {
    shouldForwardProp: ShouldNotForwardProps(['loading']),
})<StyledImageWrapperProps>(
    ({ theme }) => ({
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        display: 'inherit',
        aspectRatio: 'inherit',
        img: {
            opacity: 1,
            transitionProperty: 'opacity',
            transitionDuration: '.15s',
            transitionTimingFunction: theme.easings.smoothEase,
        },
    }),
    ifProp({ loading: true, disableSkeleton: false }, ({ theme }) => ({
        backgroundColor: theme.colors.brownUltraLight,
        overflow: 'hidden',
        img: {
            opacity: 0,
        },
    }))
);
