import React, { InputHTMLAttributes } from 'react';
import { useInputField } from '$shared/utils';
import {
    StyledCheckboxWrapper,
    StyledCheckboxElement,
    StyledCheckbox,
    StyledCheckboxIndicator,
    StyledLabel,
    StyledCheckboxHelpTexts,
    StyledCheckboxIcon,
} from './style';
import { controllableInputElementsProps } from '$shared/components/form';
import { StyledInvalidMessage } from '../../invalid-message';
import { StyledHelpText } from '../../help-text';
import { useController } from 'react-hook-form';

export type RadioElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder'> &
    controllableInputElementsProps & {
        /**
         * In case you wanna do your own
         */
        hideInvalidMessage?: boolean;

        /**
         * Toggle between checkbox or radio
         */
        type?: 'checkbox' | 'radio';
        title?: string;
        name: string;
    };

export const RadioElement = ({
    name,
    label,
    helpText,
    title,
    isInvalid,
    invalidMessage,
    id,
    type = 'checkbox',
    hideInvalidMessage,
    onChange: customOnChange,
    ...rest
}: RadioElementProps) => {
    const {
        field: { onChange, value },
        fieldState: { invalid },
    } = useController({
        name,
    });

    const { fieldId, helpTextId, describedById, showHelpText } = useInputField({
        id,
        helpText,
        isInvalid,
        invalidMessage,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (customOnChange) {
            customOnChange(event);
        }

        onChange(event);
    };

    return (
        <div key={fieldId}>
            <StyledCheckboxWrapper valid={!invalid} round>
                <StyledCheckboxElement disabled={rest.disabled} round={type === 'radio'}>
                    <StyledCheckbox
                        type={type}
                        onChange={handleChange}
                        id={name}
                        name={name}
                        aria-describedby={describedById}
                        value={value}
                        {...rest}
                    />
                    <StyledCheckboxIndicator round={type === 'radio'} />
                    <StyledCheckboxIcon color="white" size={12} />
                </StyledCheckboxElement>

                {label ? (
                    <StyledLabel
                        title={title}
                        disabled={rest.disabled}
                        htmlFor={fieldId}
                        children={label}
                    />
                ) : null}
            </StyledCheckboxWrapper>

            <StyledCheckboxHelpTexts>
                {invalidMessage && !hideInvalidMessage ? (
                    <StyledInvalidMessage children={invalidMessage} />
                ) : null}

                {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
            </StyledCheckboxHelpTexts>
        </div>
    );
};

export default RadioElement;
