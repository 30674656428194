'use client';
import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { StyledText } from './styled';
import type { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import { TextVariants } from '~/theme/text-styles';

export type TextProps = {
    variant?: TextVariants;
    color?: string;
};

/**
 * Override rendered markup with `as` attribute. For instance:
 *
 * ```tsx
 * <Text as="span">An span in markup.</Text>
 * ```
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const TextComponent: ForwardRefComponent<typeof StyledText, TextProps> = React.forwardRef(
    ({ variant, as, ...restProps }, ref) => {
        return <StyledText ref={ref} variant={variant} as={as} {...restProps} />;
    }
);

export const Text: StyledComponent<Record<string, unknown>, TextProps> = styled(TextComponent)``;
