import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { keyframes } from '@emotion/react';
import { breakpoints } from '~/theme/breakpoints';

type StyledAccordionContentProps = {
    disableAnimation?: boolean;
};

const openAnimation = keyframes({
    from: { height: 0, transform: 'translatey(-5px)', opacity: 0.5, padding: 0 },
    to: { height: 'var(--radix-accordion-content-height)' },
});

const closeAnimation = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0, transform: 'translatey(-5px)', opacity: 0.5, padding: 0 },
});

export const StyledAccordionItem = styled(RadixAccordion.Item)({
    width: '100%',
    display: 'block',
    listStyle: 'none',

    '& + &': {
        borderTop: 'none',
    },
});

export const StyledAccordionTrigger = styled(RadixAccordion.Trigger)(({ theme }) => ({
    display: 'grid',
    gap: theme.space[4],
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    padding: `${theme.space[3]} 0`,

    // Button reset
    backgroundColor: 'transparent',
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    textAlign: 'left',
    color: theme.colors.white,
    backfaceVisibility: 'hidden',
    webkitTapHighlightColor: 'transparent',
}));

export const StyledAccordionIconWrapper = styled.div({
    /**
     * Handle icon animations through the data-state
     * @see https://www.radix-ui.com/docs/primitives/components/accordion#rotated-icon-when-open
     */
    // transitionDuration: '.2s',
    // '[data-state=open] &': {
    //     transform: 'scaleY(-1)'
    // },
});

export const StyledAccordionContent = styled.div(({ theme }) => ({
    paddingBottom: theme.space[1],
}));

export const StyledAccordionContainer = styled(RadixAccordion.Content, {
    /**
     * Prevent passing `disableAnimation` to `RadixAccordion.Content`.
     * This will cause an invalid prop error
     */
    shouldForwardProp: (prop) => isPropValid(prop as string) && prop !== 'disableAnimation',
})<StyledAccordionContentProps>(({ disableAnimation, theme }) => ({
    overflow: 'hidden',
    animationDuration: disableAnimation ? '0ms' : '250ms',
    animationTimingFunction: theme.easings.smoothEase,

    [breakpoints.reducedMotion]: {
        animationDuration: '0ms',
    },

    '&[data-state="open"]': {
        animationName: openAnimation,
    },
    '&[data-state="closed"]': {
        animationName: closeAnimation,
    },
}));

export const StyledDefaultIcon = styled.div({
    fontSize: '1.2em',
    path: {
        strokeWidth: '2px',
    },
});
