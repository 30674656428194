import React, { FC } from 'react';
import { ReactHookForm__InputProps } from '../../../models/form-models';
import { InputElement, InputElementProps } from '../../controllable-elements/input-element';
import { InputCheckmark } from '../../controllable-elements/input-element/styled';

export type InputProps = InputElementProps & ReactHookForm__InputProps;

export const Input: FC<InputProps> = ({ name, type, appendWhenValid = true, ...rest }) => {
    return (
        <InputElement
            name={name}
            type={type}
            {...(appendWhenValid && { appendWhenValid: <InputCheckmark /> })}
            {...rest}
        />
    );
};
