import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { ContainerProps } from './Container';
import { breakpoints } from '~/theme/breakpoints';

export const StyledContainer = styled.div<ContainerProps>(
    ({ theme }) => ({
        margin: `0 auto`,
        maxWidth: theme.sizes.contentMaxWidth,
        width: '100%',
    }),
    ifProp('hasGutter', {
        paddingLeft: 25,
        paddingRight: 25,
        [breakpoints.max]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    }),
    ifProp('hasVerticalGutter', ({ theme }) => ({
        paddingTop: theme.space[5],
        paddingBottom: theme.space[5],
    }))
);
