import { FC } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { StyledInvalidMessage } from './styled';
export type InvalidMessageProps = {
    forField?: string;
};
export const InvalidMessage: FC<InvalidMessageProps> = ({ forField }) => {
    const {
        formState: { errors },
    } = useFormContext();

    if (!forField) return null;

    const { message: errorMessage }: FieldError = errors[forField] || {};

    return errorMessage ? <StyledInvalidMessage children={errorMessage} /> : null;
};
