import { theme } from '$theme';
import { StylesConfig } from 'react-select';
import { Option } from './selector-element';

export type reactSelectCustomStylesProps = {
    isInvalid?: boolean;
};

export const customStyles = ({
    isInvalid,
}: reactSelectCustomStylesProps): StylesConfig<Option> => ({
    container: (provided) => ({
        ...provided,
        marginTop: theme.space[1],
        marginBottom: theme.space[1],
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        borderRadius: 0,
        border: `1px solid ${isInvalid ? theme.colors.negative : theme.colors.black}`,
        borderTop: '0',
        borderBottomLeftRadius: theme.sizes.cornerRadius,
        borderBottomRightRadius: theme.sizes.cornerRadius,
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        ...(state.isFocused || state.hasValue
            ? theme.mixins.useTextStyle('formsLabelActive')
            : theme.mixins.useTextStyle('formsLabel')),
        color: theme.colors.black,
        textOverflow: 'ellipsis',
        transition: '0.1s transform, 0.1s width',
        transformOrigin: 'left top',
        textTransform: 'uppercase',
        position: 'absolute',
        backgroundColor: theme.colors.white,
        transform: state.isFocused || state.hasValue ? 'translateY(-24px)' : undefined,
    }),

    input: (provided) => ({
        ...provided,
        marginLeft: 0,
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: theme.colors.black,
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: theme.space[3],
        overflow: 'inherit',
    }),

    control: (provided, state) => ({
        ...provided,

        border: `1px solid ${isInvalid ? theme.colors.negative : theme.colors.black}`,
        borderRadius: theme.sizes.cornerRadius,

        borderBottomLeftRadius: state.menuIsOpen ? 0 : theme.sizes.cornerRadius,
        borderBottomRightRadius: state.menuIsOpen ? 0 : theme.sizes.cornerRadius,
        boxShadow: 'none',
        height: '48px',

        ':hover': {
            border: `1px solid ${isInvalid ? theme.colors.negative : theme.colors.black}`,
        },
    }),

    option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        padding: theme.space[3],
        color: theme.colors.black,
        ':last-of-type': {
            borderBottomLeftRadius: theme.sizes.cornerRadius,
            borderBottomRightRadius: theme.sizes.cornerRadius,
        },
        ':not(:last-of-type)': {
            borderBottom: `1px solid ${theme.colors.brownLight}`,
        },
        backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? theme.colors.grey20
            : isFocused
            ? theme.colors.grey10
            : undefined,

        ':active': {
            ...provided[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                    ? theme.colors.grey20
                    : theme.colors.grey20
                : undefined,
        },
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided,
            opacity,
            transition,
            fontSize: '17px',
            marginLeft: 0,
            color: theme.colors.black,
        };
    },
});
