import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { switchProp } from 'styled-tools';
import { mq } from '~/lib';

export type SplashVariants = 'campaign';

export interface SplashProps {
    text?: string;
    variant?: SplashVariants;
    children?: ReactNode;
}

export const StyledSplash = styled.span<SplashProps>(
    ({ theme }) => ({
        ...theme.mixins.useTextStyle('productInfo'),
        fontWeight: 500,
        display: 'inline-block',
        borderRadius: 4,
        padding: '3px 6px',
        position: 'absolute',
    }),
    switchProp('variant', {
        campaign: ({ theme: { colors } }) => ({
            backgroundColor: colors.primary,
            color: colors.white,
        }),
    })
);

export const PositionSplash = styled.div({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    top: -15,
    width: `100%`,
    position: 'absolute',
    zIndex: 2,
    [mq(0, 'sm')]: {
        top: -9,
    },
});

export const Splash = (props: SplashProps) => (
    <PositionSplash>
        <StyledSplash {...props} />
    </PositionSplash>
);
