import React, { InputHTMLAttributes, useImperativeHandle, useRef } from 'react';
import { useInputField } from '$shared/utils';
import {
    StyledCheckboxWrapper,
    StyledCheckboxElement,
    StyledCheckbox,
    StyledCheckboxIndicator,
    StyledLabel,
    StyledCheckboxHelpTexts,
    StyledCheckboxIcon,
} from './style';
import { controllableInputElementsProps } from '$shared/components/form';
import { StyledInvalidMessage } from '../../invalid-message';
import { StyledHelpText } from '../../help-text';

export type CheckboxElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder'> &
    controllableInputElementsProps & {
        /**
         * In case you wanna do your own
         */
        hideInvalidMessage?: boolean;

        /**
         * Toggle between checkbox or radio
         */
        type?: 'checkbox' | 'radio';
        title?: string;
    };

export const CheckboxElement = React.forwardRef<HTMLInputElement, CheckboxElementProps>(
    (
        {
            label,
            helpText,
            title,
            isInvalid,
            invalidMessage,
            id,
            type = 'checkbox',
            hideInvalidMessage,
            ...rest
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        const isValid = !invalidMessage && !isInvalid;

        const { fieldId, helpTextId, describedById, showHelpText } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const showStyledCheckboxHelpText = (invalidMessage && !hideInvalidMessage) || showHelpText;

        return (
            <div key={fieldId}>
                <StyledCheckboxWrapper round={type === 'radio'} valid={isValid}>
                    <StyledCheckboxElement disabled={rest.disabled} round={type === 'radio'}>
                        <StyledCheckbox
                            {...rest}
                            type={type}
                            id={fieldId}
                            ref={inputRef}
                            aria-describedby={describedById}
                        />
                        <StyledCheckboxIndicator round={type === 'radio'} />
                        <StyledCheckboxIcon color="white" size={12} />
                    </StyledCheckboxElement>
                    {label ? (
                        <StyledLabel
                            title={title}
                            disabled={rest.disabled}
                            htmlFor={fieldId}
                            children={label}
                        />
                    ) : null}
                </StyledCheckboxWrapper>
                {showStyledCheckboxHelpText && (
                    <StyledCheckboxHelpTexts>
                        {invalidMessage && !hideInvalidMessage ? (
                            <StyledInvalidMessage children={invalidMessage} />
                        ) : null}

                        {showHelpText ? (
                            <StyledHelpText id={helpTextId} children={helpText} />
                        ) : null}
                    </StyledCheckboxHelpTexts>
                )}
            </div>
        );
    }
);

export default CheckboxElement;
