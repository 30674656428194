'use client';
import { useRawHtml } from './hooks';

export type RawHtmlProps = {
    html?: string;
};

export const RawHtml = ({ html = '', ...rest }: RawHtmlProps) => {
    return useRawHtml(html, rest);
};
