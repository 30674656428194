'use client';
import NextImage, { ImageProps as NextImageProps } from 'next/legacy/image';
import { useState } from 'react';
import { FocalPoint } from '~/lib';
import { useConfig } from '~/shared/hooks';
import { cloudflareLoader } from './loaders/cloudflareLoader';
import { defaultLoader } from './loaders/defaultLoader';
import { StyledImageWrapper } from './styled';

export type ImageProps = NextImageProps & {
    disableSkeleton?: boolean;
    focalPoint?: FocalPoint;
};

// @see https://github.com/vercel/next.js/blob/canary/packages/next/client/image.tsx
type OnLoadingComplete = {
    naturalWidth: number;
    naturalHeight: number;
};

export const Image = ({
    src = '',
    width: initialWidth = 0,
    height: initialHeight = 0,
    layout = 'responsive',
    disableSkeleton = false,
    alt = '',
    focalPoint,
    ...rest
}: ImageProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const {
        config: { NEXT_PUBLIC_CLOUDFLARE_LOADER_URL },
    } = useConfig();

    /**
     * @TODO Look into using custom hook to cache src dimensions
     * to prevent additional layout shifts for repeated images
     */
    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(initialHeight);

    const isProduct = src.toString().includes('asset.productmarketingcloud.com');
    const url = isProduct ? src + '/preview' : src;

    const setDimensions = layout !== 'fill';

    const loader = getLoader();

    const onLoadHandler = ({ naturalWidth, naturalHeight }: OnLoadingComplete) => {
        const noDimensions = !width || !height;
        setIsLoading(false);

        /**
         * Setting naturalWidth and naturalHeight on the image element
         * if non provided.
         * isLoading ensures only initial render.
         * Updating dimensions on the image will retrigger onLoadingComplete.
         */
        if (isLoading && noDimensions && setDimensions) {
            setWidth(naturalWidth);
            setHeight(naturalHeight);
        }
    };

    return (
        <StyledImageWrapper loading={isLoading} disableSkeleton={rest.priority || disableSkeleton}>
            <NextImage
                loader={loader}
                src={url}
                onLoadingComplete={onLoadHandler}
                width={width}
                height={height}
                layout={layout}
                alt={alt}
                objectPosition={
                    focalPoint ? `${focalPoint.left * 100}% ${focalPoint.top * 100}%` : undefined
                }
                {...rest}
            />
        </StyledImageWrapper>
    );

    function getLoader() {
        const isStatic = !/^https?:\/\//.test(src.toString());
        const isSvg = src.toString().includes('.svg');
        if (isStatic) {
            return undefined;
        }
        if (isSvg) {
            return defaultLoader;
        }
        if (NEXT_PUBLIC_CLOUDFLARE_LOADER_URL) {
            return cloudflareLoader;
        }

        return defaultLoader;
    }
};
