import React, { FC, useMemo, useState, ReactElement } from 'react';
import { Root, AccordionMultipleProps } from '@radix-ui/react-accordion';
import { AccordionContext } from './context/AccordionContext';
import { AccordionItemProps } from './components';

export type AccordionProps = Omit<AccordionMultipleProps, 'value' | 'defaultValue' | 'type'> & {
    /**
     * Allows a single or multiple accordions to be open at onces.
     * `single` will automaticlly close other open accordions,
     * when opening a new accordion
     */
    type?: 'single' | 'multiple';
    children: ReactElement<AccordionItemProps> | ReactElement<AccordionItemProps>[];
    defaultValue?: string;
};

/**
 * @example
 * <Accordion>
 *     <AccordionItem header="How to request a refund." initAsOpen={true}>
 *         Reqeust a refund by...
 *     </AccordionItem>
 * </Accordion>
 */
export const Accordion: FC<AccordionProps> = ({
    type = 'multiple',
    onValueChange,
    children = [],
    defaultValue,
    ...rest
}) => {
    const [states, setOpenStates] = useState<string[]>([]);
    const [disableAnimation, setDisabledAnimation] = useState(true);

    const renderAsList = useMemo(() => {
        return React.Children.count(children) > 1;
    }, [children]);

    const setStates = (stateIds: string[]) => {
        const newStates = stateIds.filter((id, index) => {
            if (type === 'single') {
                return !states.includes(id);
            }

            // remove duplicates
            return stateIds.indexOf(id) === index;
        });

        setOpenStates(newStates);
        return newStates;
    };

    const open = (stateId: string) => {
        setStates([...states, stateId]);
    };

    const close = (stateId: string) => {
        setStates(states.filter((id) => id !== stateId));
    };

    const toggle = (stateId: string) => {
        states.includes(stateId) ? close(stateId) : open(stateId);
    };

    const onValueChangeHandler = (values: string[]) => {
        const states = setStates(values);
        const radixChangeValue = type === 'multiple' ? states : states[0] || '';

        // When user interacts with accordion, enable animations
        setDisabledAnimation(false);

        onValueChange && onValueChange(radixChangeValue as string & string[]);
    };

    return (
        <Root
            type="multiple"
            onValueChange={onValueChangeHandler}
            value={states.length ? states : defaultValue ? [defaultValue] : []}
            defaultValue={defaultValue ? [defaultValue] : []}
            {...rest}
        >
            <AccordionContext.Provider
                value={{ states, setStates, toggle, open, close, renderAsList, disableAnimation }}
            >
                {children}
            </AccordionContext.Provider>
        </Root>
    );
};
