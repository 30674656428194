import React from 'react';
import styled from '@emotion/styled';
import { theme } from '~/theme';
import { ProductLabelSetting } from '~/lib';
import { breakpoints } from '~/theme/breakpoints';
import { weakKey } from '~/shared/utils/jsx';

export const InfoWrapper = styled.div<{
    left: ProductLabelSetting[];
    right: ProductLabelSetting[];
}>(({ theme, left, right }) => ({
    ...theme.mixins.useTextStyle('productInfo'),
    boxSizing: 'border-box',
    position: 'absolute',
    width: '100%',
    padding: theme.space[3],
    display: 'flex',
    zIndex: 2,
    minHeight: '62px',
    color: theme.colors.black,
    justifyContent:
        (left?.length > 0 && right?.length > 0 && 'space-between') ||
        (right?.length > 0 && left?.length === 0 && 'flex-end') ||
        'flex-start',
    [breakpoints.sm]: {
        padding: theme.space[4],
    },
    span: {
        display: 'block',
    },
}));

export const TopInfoLabel = styled.span<{
    textColor?: string;
    backgroundColor?: string;
}>(({ backgroundColor, textColor }) => ({
    color: `#${textColor}` || theme.colors.black80,
    backgroundColor: `#${backgroundColor}` || 'none',
    padding: 0.3 + 'rem',
}));

export const TopInfo = ({
    left,
    right,
}: {
    left: ProductLabelSetting[];
    right: ProductLabelSetting[];
}) => {
    const hasLabel = (x: ProductLabelSetting) => x && x.labelText;
    return (
        <InfoWrapper left={left} right={right}>
            {left.length > 0 && (
                <div>
                    {left.filter(hasLabel).map(({ labelText, backgroundColor, textColor }) => (
                        <TopInfoLabel
                            key={weakKey(left)}
                            textColor={textColor}
                            backgroundColor={backgroundColor}
                        >
                            {labelText}
                        </TopInfoLabel>
                    ))}
                </div>
            )}

            {right.length > 0 && (
                <div>
                    {right.filter(hasLabel).map(({ labelText, backgroundColor, textColor }) => (
                        <TopInfoLabel
                            key={weakKey(right)}
                            textColor={textColor}
                            backgroundColor={backgroundColor}
                        >
                            {labelText}
                        </TopInfoLabel>
                    ))}
                </div>
            )}
        </InfoWrapper>
    );
};
