import React, { memo, RefObject, useCallback, useMemo } from 'react';
import StarIconActive from '$icons/rating-active.svg';
import { useTranslation } from '~/shared/utils/translation';
import StarIconInactive from '$icons/rating-inactive.svg';
import StarIconHalfActive from '$icons/half-star-active.svg';
import { StyledWrapper, StyledStar, StyledTotalRatings } from './styled';

export type TRating = {
    totalRatings?: number;
    rating: number;
    scrollToRef?: RefObject<HTMLDivElement>;
};

const BaseRating = ({ totalRatings, rating, scrollToRef }: TRating) => {
    const { translate } = useTranslation();

    const handleScrollToRef = useCallback(() => {
        if (totalRatings !== undefined && totalRatings > 0) {
            scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [scrollToRef, totalRatings]);

    const stars = useMemo(() => {
        const maxStars = 5;

        const renderStar = (index: number) => {
            const starValue = index + 1;

            if (starValue <= rating) {
                return <StarIconActive aria-label={translate('accessibility.star')} />;
            } else if (starValue - 0.5 <= rating) {
                return <StarIconHalfActive aria-label={translate('accessibility.halfStar')} />;
            } else {
                return <StarIconInactive aria-label={translate('accessibility.emptyStar')} />;
            }
        };

        const visual = [...Array(maxStars)].map((_, index: number) => (
            <StyledStar key={rating + index}>{renderStar(index)}</StyledStar>
        ));

        return visual;
    }, [rating, translate]);

    return (
        <StyledWrapper
            alignItems="center"
            justifyContent="center"
            aria-label={translate('accessibility.starRating')}
            onClick={handleScrollToRef}
        >
            {stars}
            <StyledTotalRatings>({totalRatings})</StyledTotalRatings>
        </StyledWrapper>
    );
};

export const Rating = memo(BaseRating);
