'use client';
import React from 'react';
import {
    DeepPartial,
    FormProvider,
    SubmitHandler,
    UnpackNestedValue,
    UseFormReturn,
} from 'react-hook-form';
import { StyledForm } from './styled';

export type FormProps<TFormValues> = {
    methods: UseFormReturn<TFormValues | any>;
    onSubmit?: SubmitHandler<TFormValues | any>;
    children?: React.ReactNode;
    defaultValues?: UnpackNestedValue<DeepPartial<TFormValues>>;
};

export const Form = <TFormValues extends Record<string, string | number | boolean>>({
    methods,
    onSubmit,
    children,
}: FormProps<TFormValues>) => {
    const { handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <StyledForm {...(onSubmit && { onSubmit: handleSubmit(onSubmit) })}>
                {children}
            </StyledForm>
        </FormProvider>
    );
};
