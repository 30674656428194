import styled from '@emotion/styled';

export const StyledCarousel = styled.div(() => ({
    maxWidth: '100vw',
    overflow: 'hidden',

    '.swiper-container': {
        overflow: 'visible',
    },
    '.swiper': {
        height: '100%',
    },

    '.swiper-slide': {
        width: 'auto',
        height: 'auto',
        margin: '10px 0',
    },

    '.swiper-button-disabled': {
        opacity: 0.5,
    },

    '.swiper-button-lock': {
        display: 'none',
    },
}));
