import React, { ReactNode } from 'react';
import { StyledButtonContent } from '~/shared/components/Button/styled';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';

export const Content = ({
    description,
    children,
}: {
    description: string;
    children: ReactNode;
}) => {
    return (
        <>
            <StyledButtonContent aria-hidden="true">{children}</StyledButtonContent>
            <VisuallyHidden>{description}</VisuallyHidden>
        </>
    );
};
