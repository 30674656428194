'use client';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { StyledLink } from './styled';

type AnchorProps = React.HTMLProps<HTMLAnchorElement>;

export type LinkProps = Pick<AnchorProps, 'target' | 'title' | 'className'> & {
    /**
     * The path or URL to navigate to. This is the only required prop.
     */
    href?: NextLinkProps['href'];

    /**
     * Refetch the page in the background. Any `<Link />` that is in the viewport (initially or through scroll) will be preloaded.
     * When prefetch is set to false, prefetching will still occur on hover.
     * Pages using Static Generation will preload JSON files with the data for faster page transitions.
     * _Prefetching is only enabled in production_.
     */
    prefetch?: NextLinkProps['prefetch'];

    /**
     *  Replace the current history state instead of adding a new url into the stack.
     */
    replace?: NextLinkProps['replace'];

    /**
     *  Scroll to the top of the page after a navigation
     */
    scroll?: NextLinkProps['scroll'];

    /**
     * Update the path of the current page without rerunning `getStaticProps`, `getServerSideProps` or `getInitialProps`
     */
    shallow?: NextLinkProps['shallow'];
    noHover?: boolean;
    color?: string;
    children: React.ReactNode;
    onClick?: (e: React.SyntheticEvent) => void;
};

export const Link = ({
    href,
    prefetch,
    replace,
    shallow,
    color,
    scroll = true,
    title,
    ...rest
}: LinkProps) => {
    const { className = '' } = rest;

    /**
     * Use a plain <a /> when compositioned with a button,
     * to prevent style conflicts
     */
    const isCompositionedButton = className.match(/css-(.+)-(.+)?Button/);

    if (isCompositionedButton) {
        return (
            <NextLink
                aria-label={title}
                href={href || ''}
                passHref
                prefetch={prefetch}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                css={{ color: color }}
                {...rest}
            />
        );
    }

    return (
        <StyledLink
            aria-label={title}
            href={href || ''}
            passHref
            prefetch={prefetch}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
            color={color}
            {...rest}
        />
    );
};
