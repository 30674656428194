import React from 'react';
import type { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import { StyledButton } from './styled';
import { ReactNode } from 'react';
import { Content } from './components/content';
import { ButtonColorProps } from '~/lib';

export type ButtonProps = {
    /**
     * Which theme variant to display button as
     */
    variant?: ButtonColorProps;
    color?: string;
    backgroundColor?: string;
    textColor?: string;
    small?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;

    size?: 'small' | 'large';
    /**
     * Description of what the button does, this is for screen readers
     * and should be used when content does not describe the action
     */
    description?: string;

    children: ReactNode;
    alternativeTextColor?: string;
    alternativeBackgroundColor?: string;
    onClick?: (e: React.SyntheticEvent) => void;
};

/**
 * Handles all the button styles across the site.
 *
 * The proper way to use as link is `as={Link}`. This hooks into the router and performance optimisations.
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const Button: ForwardRefComponent<typeof StyledButton, ButtonProps> = React.forwardRef(
    ({ variant = 'primary', description, children, color, title, ...restProps }, ref) => {
        return (
            <StyledButton
                aria-label={title}
                color={color}
                ref={ref}
                variant={variant || 'primary'}
                {...restProps}
            >
                {description ? <Content description={description} children={children} /> : children}
            </StyledButton>
        );
    }
);
