import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Image } from '~/shared/components';

interface IProps {
    src?: string;
    alt?: string;
}

export const SwatchColor: FC<IProps> = ({ src, alt = '' }) => {
    return (
        <StyledRoot hasImage={!!src}>
            {src ? <Image height={20} width={20} alt={alt} src={src} /> : null}
        </StyledRoot>
    );
};

const StyledRoot = styled.div<{ hasImage?: boolean }>(({ theme, hasImage }) => ({
    borderRadius: '100%',
    border: `1px solid ${hasImage ? theme.colors.grey30 : 'transparent'}`,
    overflow: 'hidden',
    width: theme.space['3'],
    height: theme.space['3'],
    flexShrink: 0,
}));
