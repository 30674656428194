import React, { FC } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { ReactHookForm__InputProps } from '../../../models/form-models';
import { RadioElementProps, RadioElement } from '../../controllable-elements/radio-element';

export type CheckboxProps = RadioElementProps & ReactHookForm__InputProps;

export const Radio: FC<CheckboxProps> = ({ name, ...rest }) => {
    const {
        formState: { errors },
    } = useFormContext();

    const errorObj: FieldError = errors[name];
    const { message: errorMessage } = errorObj || {};

    return (
        <RadioElement
            name={name}
            isInvalid={!!errorObj}
            invalidMessage={errorMessage}
            type="radio"
            {...rest}
        />
    );
};
