import { ReactHookForm__InputProps } from '$shared/components/form/models/form-models';
import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import {
    SelectorElement,
    SelectorElementProps,
} from '../../controllable-elements/selector-element';

export type SelectorProps = SelectorElementProps & ReactHookForm__InputProps;
export const Selector: FC<SelectorProps> = ({ name, rules, ...props }) => {
    const {
        field: { value, onChange },
        fieldState: { invalid, error },
    } = useController({
        defaultValue: props.options.filter((o) => o.checked),
        name,
        rules,
    });

    const { message: errorMessage } = error || {};

    return (
        <SelectorElement
            controlledValue={value}
            onChange={onChange}
            isInvalid={invalid}
            invalidMessage={errorMessage}
            isRequired={!!rules?.required}
            {...props}
        />
    );
};

export default Selector;
