import VideoPlayerLazy from 'react-player/lazy';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { fetchVideoMetadata } from './utils';
import { StyledPlayButton, StyledVideoAspect, StyledVideoContainer } from './styled';
import PlayBtn from '$icons/playbtn.svg';

export interface PlayButtonProps {
    playing?: boolean;
}

export interface VideoProps {
    /** Video source. Supports YouTube, Vimeo and selfhosted */
    src: string;

    /**
     * Required if selfhosted. Overwrites default poster for YouTube and Vimeo.
     * Default posters from external providers are very low resultion.
     * Providing a posterSrc is always prefered.
     */
    posterSrc?: string;

    /**
     * Show or hide controls. Only the play button will be visible until playing.
     * YouTube: Not all controls can be hidden from youtube.
     * Vimeo: Hidden controls must be enabled by video owner
     */
    controls?: boolean;

    /** Start the video muted. Prefered */
    muted?: boolean;

    /** Loop the video */
    loop?: boolean;

    /** Start playing imidiately */
    playing?: boolean;

    /**
     *  Trigger recalcuating dimensions and repositioning the video.
     *  Usefull in cases where the container resizes without an window resize event
     */
    repositionKey?: string;

    /**
     * Aspect ratio.
     * If not set will automatically determine (requires an additional requst)
     */
    aspectRatio?: number;

    /**
     * Set height to 100%
     */
    cover?: boolean;

    /*
    Lock height to video and fill black bars for remaining area horizontally
    */
    theaterMode?: boolean;

    /**
     * Disable or overwride playbutton
     */
    playButton?: null | (({ playing }: PlayButtonProps) => JSX.Element);

    /**
     * Triggered when started playing
     */
    onPlay?: () => void;

    /**
     * Triggered when stopd playing
     */
    onPause?: () => void;

    /**
     * Video provider specific options
     * @see https://www.npmjs.com/package/react-player
     */
    embedConfig?: { [key: string]: unknown };
}

const defaultEmbedOptions = {
    youtube: {
        embedOptions: {
            rel: 0,
            modestbranding: 1,
        },
    },
};

export const Video = ({
    controls = true,
    muted = false,
    loop = false,
    playing = false,
    cover = false,
    theaterMode = false,
    src,
    posterSrc,
    repositionKey,
    onPlay,
    onPause,
    embedConfig = defaultEmbedOptions,
    playButton: VideoPlayButton,
    aspectRatio: defaultAspectRatio,
}: VideoProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);
    const [isPlaying, setIsPlaying] = useState(playing);
    const [initialPlay, setInitialPlay] = useState(playing);
    const [thumbnailSrc, setThumbnailSrc] = useState(posterSrc);

    const styles = useMemo(
        () => ({
            backgroundImage: thumbnailSrc ? `url(${thumbnailSrc})` : '',
            // Fallback to 16/9
            paddingTop: cover ? 0 : aspectRatio ? `${aspectRatio * 100}%` : '56.25%',
        }),
        [thumbnailSrc, aspectRatio],
    );

    const onPlayHandler = () => {
        setInitialPlay(true);
        setIsPlaying(true);
        onPlay && onPlay();
    };

    const onPauseHandler = () => {
        setIsPlaying(false);
        onPause && onPause();
    };

    /**
     * Only reposition if covering. Otherwise fluid
     */
    useEffect(() => {
        if (!aspectRatio || !cover) {
            return;
        }

        /**
         * Determin if stretch horizontal or vertical
         */
        const scaleVideo = () => {
            const aspectElement = ref?.current;

            if (aspectElement) {
                // ANK:
                // This part needs refactoring with proper React-based support, because updating the DOM jQuery-style messes
                // with the state of other components, ie the IntersectionObserver used in M10Hero

                const parentElement = aspectElement.parentElement as HTMLDivElement;
                const { width, height } = parentElement.getBoundingClientRect();

                const parentRatio = height / width;
                const verticalScale = parentRatio > aspectRatio;

                if (theaterMode) {
                    aspectElement.style.height = '100%';
                } else if (verticalScale) {
                    aspectElement.style.height = '100%';
                    aspectElement.style.width = '1000%';
                } else {
                    aspectElement.style.width = '100%';
                    aspectElement.style.height = '1000%';
                }
            }
        };

        scaleVideo();
        window.addEventListener('resize', scaleVideo);
        return () => window.removeEventListener('resize', scaleVideo);
    }, [aspectRatio, repositionKey]);

    useEffect(() => {
        if (!aspectRatio || !thumbnailSrc) {
            fetchVideoMetadata(src).then(
                ({ aspectRatio: newAspectRatio, thumbnailSrc: newThumbnailSrc }) => {
                    aspectRatio || setAspectRatio(newAspectRatio);
                    thumbnailSrc || setThumbnailSrc(newThumbnailSrc);
                },
            );
        }
    }, []);

    useEffect(() => {
        playing ? onPlayHandler() : onPauseHandler();
    }, [playing]);

    return (
        <StyledVideoContainer
            css={styles}
            controls={controls}
            cover={cover}
            playing={isPlaying}
            theaterMode={theaterMode}
            onClick={onPlayHandler}
        >
            <StyledVideoAspect ref={ref}>
                {initialPlay ? (
                    <VideoPlayerLazy
                        key={src}
                        url={src}
                        playing={isPlaying || initialPlay}
                        controls={isPlaying && controls}
                        muted={muted}
                        loop={loop}
                        onPause={onPauseHandler}
                        onPlay={onPlayHandler}
                        config={embedConfig}
                    />
                ) : null}
            </StyledVideoAspect>
            {VideoPlayButton ? (
                <VideoPlayButton playing={isPlaying} />
            ) : (
                <StyledPlayButton variant="plain" playing={isPlaying} onClick={onPlayHandler}>
                    <PlayBtn />
                </StyledPlayButton>
            )}
        </StyledVideoContainer>
    );
};
export default Video;
