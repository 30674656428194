import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { TextProps } from '.';

export const StyledText = styled.p<TextProps>(
    ({ theme, color }) => ({
        ...theme.mixins.useTextStyle('body'),
        color: color ? `#${color?.replace(/#/g, '')}` : theme.colors.black,

        img: {
            maxWidth: '100%',
            height: 'auto',
        },
    }),
    switchProp('as', {
        i: {
            fontStyle: 'italic',
        },
        em: {
            fontStyle: 'italic',
        },
        strong: ({ theme }) => ({
            fontWeight: theme.fontWeights.bold,
        }),
        b: ({ theme }) => ({
            fontWeight: theme.fontWeights.bold,
        }),
    }),
    switchProp('size', {
        xs: ({ theme }) => ({ ...theme.mixins.useTextStyle('xs') }),
        small: ({ theme }) => ({ ...theme.mixins.useTextStyle('small') }),
        large: ({ theme }) => ({ ...theme.mixins.useTextStyle('large') }),
    }),
    switchProp('variant', {
        body: ({ theme }) => ({
            ...theme.mixins.useTextStyle('body'),
        }),
        caption: ({ theme }) => ({
            ...theme.mixins.useTextStyle('caption'),
            color: theme.colors.grey100,
        }),
        bodySmall: ({ theme }) => ({
            ...theme.mixins.useTextStyle('bodySmall'),
        }),
        displayGiant: ({ theme }) => ({
            ...theme.mixins.useTextStyle('displayGiant'),
        }),
        display0: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display0'),
        }),
        display1: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display1'),
        }),
        display2: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display2'),
        }),
        display3: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display3'),
        }),
        display4: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display4'),
        }),
        bigCampaign: ({ theme }) => ({
            ...theme.mixins.useTextStyle('bigCampaign'),
        }),
        smallCampaign: ({ theme }) => ({
            ...theme.mixins.useTextStyle('smallCampaign'),
        }),
        productInfo: ({ theme }) => ({
            ...theme.mixins.useTextStyle('productInfo'),
        }),
        navigationHeader: ({ theme }) => ({
            ...theme.mixins.useTextStyle('navigationHeader'),
        }),
        displaySaldoNumber: ({ theme }) => ({
            ...theme.mixins.useTextStyle('displaySaldoNumber'),
        }),
    })
);
