import React, { FC } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { ReactHookForm__InputProps } from '../../../models/form-models';
import {
    CheckboxElementProps,
    CheckboxElementRHF,
} from '../../controllable-elements/checkbox-element';

export type CheckboxProps = CheckboxElementProps & ReactHookForm__InputProps;

export const Checkbox: FC<CheckboxProps> = ({ name, ...rest }) => {
    const {
        formState: { errors },
    } = useFormContext();

    const errorObj: FieldError = errors[name];
    const { message: errorMessage } = errorObj || {};

    return (
        <CheckboxElementRHF
            name={name}
            isInvalid={!!errorObj}
            invalidMessage={errorMessage}
            {...rest}
        />
    );
};
