'use client';
import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

type Props = {
    column?: boolean;
    flex?: boolean;
    justifyContent?:
        | 'start'
        | 'center'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly';
    alignItems?:
        | 'start'
        | 'center'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'baseline'
        | 'stretch';
    spacingBottom?: 'extrasmall' | 'small' | 'medium' | 'large';
    wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
    grow?: boolean;
    gap?: 'extrasmall' | 'small' | 'medium' | 'large';
    className?: string;
    style?: CSSProperties;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: React.ElementType<any> | undefined;
};

export const Flex: React.FC<React.PropsWithChildren<Props>> = (
    props: React.PropsWithChildren<Props>
): JSX.Element => <FlexLayout {...props}>{props.children}</FlexLayout>;

const FlexLayout = styled.div<Props>(
    ({ flex, column, justifyContent, alignItems, wrap, grow }) => ({
        display: 'flex',
        flex: flex ? 1 : 'initial',
        flexDirection: column ? 'column' : 'row',
        justifyContent: justifyContent ? justifyContent : 'initial',
        alignItems: alignItems ? alignItems : 'initial',
        flexWrap: wrap || 'initial',
        width: grow ? '100%' : undefined,
    }),
    switchProp('spacingBottom', {
        extrasmall: ({ theme: { space } }) => ({
            marginBottom: space[2],
        }),
        small: ({ theme: { space } }) => ({
            marginBottom: space[3],
        }),
        medium: ({ theme: { space } }) => ({
            marginBottom: space[4],
        }),
        large: ({ theme: { space } }) => ({
            marginBottom: space[5],
        }),
    }),
    switchProp('gap', {
        extrasmall: ({ theme: { space }, column }) => ({
            '> *:not(:last-child)': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginRight: !column ? space[2] : 'initial',
                marginTop: column ? space[2] : 'initial',
            },
        }),
        small: ({ theme: { space }, column }) => ({
            '> *:not(:last-child)': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginRight: !column ? space[3] : 'initial',
                marginTop: column ? space[3] : 'initial',
            },
        }),
        medium: ({ theme: { space }, column }) => ({
            '> *:not(:last-child)': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginRight: !column ? space[4] : 'initial',
                marginTop: column ? space[4] : 'initial',
            },
        }),
        large: ({ theme: { space }, column }) => ({
            '> *:not(:last-child)': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginRight: !column ? space[5] : 'initial',
                marginTop: column ? space[5] : 'initial',
            },
        }),
    })
);

export default Flex;
