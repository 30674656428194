import React from 'react';
import styled from '@emotion/styled';
import { Image } from '~/shared/components/Image';
import Plus from '$icons/plus.svg';
import { weakKey } from '~/shared/utils/jsx';

type Props = {
    swatchesOptions?: {
        color: string;
        alt: string;
        url: string;
        title: string;
    }[];
};

export const Swatches = (props: Props) => {
    const { swatchesOptions } = props;
    return (
        <SwatchesDiv>
            {Array.isArray(swatchesOptions) &&
                swatchesOptions.slice(0, 3)?.map((item) => {
                    return (
                        <SwatchesElement key={weakKey(item)}>
                            <Image height={20} width={20} alt={item.alt} src={item.url} />
                        </SwatchesElement>
                    );
                })}
            {Array.isArray(swatchesOptions) && swatchesOptions?.length > 3 && <PlusOptions />}
        </SwatchesDiv>
    );
};

export const SwatchesDiv = styled.div(({ theme }) => ({
    position: 'absolute',
    left: theme.space['4'],
    bottom: theme.space['4'],
    maxWidth: '50px',
    height: theme.space['4'],
    display: 'flex',
    alignItems: 'center',
}));

export const SwatchesElement = styled.div(({ theme }) => ({
    borderRadius: '50%',

    overflow: 'hidden',
    position: 'absolute',
    width: theme.space['3'],
    height: theme.space['3'],
    left: '10px',
    ':first-of-type': {
        left: 0,
        zIndex: 1,
    },
    ':nth-of-type(2)': {
        left: '10px',
        zIndex: 2,
    },
    ':nth-of-type(3)': {
        left: '20px',
        zIndex: 3,
    },
}));

export const PlusOptions = styled(Plus)(({ theme }) => ({
    color: theme.colors.black,
    fill: theme.colors.black,
    width: '8px',
    height: '8px',
    position: 'absolute',
    left: '40px',
    '& path': {
        fill: theme.colors.black,
        strokeWidth: 4,
    },
}));
